import {
  Box,
  Button,
  Link,
  Menu,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Warn_Banner from "./global/Warn_Banner";
import { gql, useMutation } from "@apollo/client";
import { Custom_Build } from "../backend-generated-types";
import Icon_Desktop from "./icons/Icon_Desktop";
import { isNil } from "lodash";
import { sanitize_input, sanitize_number_input } from "../utils";

type Props = {
  build: Custom_Build;
  handle_delete: (id: string) => void;
  handle_rename: (id: string, new_name: string) => void;
  handle_sold: (id: string, sold_amount: number) => void;
};

const appraisal_text_style = {
  textAlign: "center" as const,
};

const garage_item_style = {
  margin: "20px",
  maxWidth: "250px",
  maxHeight: "300px",
  display: "flex",
  flexDirection: "column" as const,
  position: "relative" as "relative",
};

const triple_dot_btn_style = {
  position: "absolute" as const,
  top: 0,
  right: 15,
  zIndex: 200,
};

const desktop_graphic_style = {
  marginTop: 10,
  width: "80%",
  height: "150px",
  marginLeft: "auto",
  marginRight: "auto",
};

const row_container_style = {
  display: "flex",
  flexDirection: "row" as const,
  justifyContent: "right",
};

const modal_style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  color: "text.primary",
  border: "1px solid green",
  p: 4,
  fontFamily: "Ubuntu",
};

const Garage_Item = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // modal states
  const [delete_modal_open, set_delete_modal_open] = React.useState(false);
  const handle_delete_modal_open = () => set_delete_modal_open(true);
  const handle_delete_modal_close = () => set_delete_modal_open(false);
  const [rename_modal_open, set_rename_modal_open] = React.useState(false);
  const handle_rename_modal_open = () => set_rename_modal_open(true);
  const handle_rename_modal_close = () => set_rename_modal_open(false);
  const [sold_modal_open, set_sold_modal_open] = React.useState(false);
  const handle_sold_modal_open = () => set_sold_modal_open(true);
  const handle_sold_modal_close = () => set_sold_modal_open(false);

  const [sold_amount, set_sold_amount] = useState<number | undefined>(undefined);
  const [new_name, set_new_name] = useState<string | undefined>(undefined);

  const triple_dot_open = Boolean(anchorEl);
  const [mouseover, set_mouseover] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handle_close = () => {
    setAnchorEl(null);
  };
  const execute_deletion = () => {
    delete_custom_build_mutation({
      variables: {
        id: props.build?.id,
      },
    }).then(() => {
      handle_delete_modal_close();
      props.handle_delete(props.build?.id);
    });
  };
  const execute_mark_as_sold = () => {
    if (!isNil(sold_amount)) {
      mark_as_sold_mutation({
        variables: {
          id: props.build?.id,
          sold_amount: sold_amount,
        },
      }).then((r) => {
        handle_sold_modal_close();
        props.handle_sold(props.build?.id, sold_amount);
      });
    }
  };
  const execute_rename = () => {
    if (!isNil(new_name)) {
      rename_custom_build_mutation({
        variables: {
          id: props.build?.id,
          new_name: new_name,
        },
      }).then(() => {
        handle_rename_modal_close();
        props.handle_rename(props.build?.id, new_name);
      });
    }
  };
  const [delete_custom_build_mutation] = useMutation(gql`
    mutation Delete_Custom_Build($id: ID!) {
      custom_build_delete(id: $id) {
        id
      }
    }
  `);
  const [rename_custom_build_mutation] = useMutation(gql`
    mutation Rename_Custom_Build($id: ID!, $new_name: String!) {
      rename_custom_build(custom_build_id: $id, new_name: $new_name) {
        id
        name
      }
    }
  `);
  const [mark_as_sold_mutation] = useMutation(gql`
    mutation Mark_Sold_Custom_Build($id: ID!, $sold_amount: Float!) {
      mark_as_sold(custom_build_id: $id, sold_amount: $sold_amount) {
        id
        sold_amount
      }
    }
  `);

  return (
    <>
      <div
        style={garage_item_style}
        onMouseOver={() => set_mouseover(true)}
        onMouseLeave={() => set_mouseover(false)}
      >
        {mouseover && (
          <>
            <Button
              id="basic-button"
              variant={"outlined"}
              aria-controls={
                triple_dot_open ? "garage-item-overlay" : undefined
              }
              aria-haspopup="true"
              aria-expanded={triple_dot_open ? "true" : undefined}
              onClick={handleClick}
              style={triple_dot_btn_style}
              sx={{
                backgroundColor: "#fff",
                "&:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <MoreHorizIcon />
            </Button>
            <Menu
              id="garage-item-overlay"
              anchorEl={anchorEl}
              open={triple_dot_open}
              onClose={handle_close}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handle_close();
                  handle_rename_modal_open();
                }}
              >
                Rename
              </MenuItem>
              {isNil(sold_amount) && <MenuItem
                onClick={() => {
                  handle_close();
                  handle_sold_modal_open();
                }}
              >
                Mark as Sold
              </MenuItem>}
              <MenuItem
                onClick={() => {
                  handle_close();
                  handle_delete_modal_open();
                }}
              >
                Delete
              </MenuItem>
            </Menu>
          </>
        )}

        <div style={desktop_graphic_style}>
          <Icon_Desktop
            is_static={true}
            cpu_enabled={!!props.build?.cpus?.length}
            gpu_enabled={!!props.build?.gpus?.length}
            psu_enabled={!!props.build?.psus?.length}
            cpu_cooler_enabled={!!props.build?.cpu_coolers?.length}
            case_enabled={!!props.build?.case?.id}
            case_fan_enabled={!!props.build?.case_fans?.length}
            motherboard_enabled={!!props.build?.motherboard?.id}
            operating_system_enabled={!!props.build?.operating_systems?.length}
            ram_enabled={!!props.build?.rams?.length}
            storage_enabled={!!props.build?.storages?.length}
            max_width={200}
          />
        </div>
        <Link href={`/builds/${props.build?.id}`} underline={"none"}>
          <Typography style={{ ...appraisal_text_style, color: "black" }}>
            {!isNil(props.build?.sold_amount) && `[SOLD]`}
            {props.build?.name}
          </Typography>
          {props.build?.latest_appraisal_price && (
            <Typography
              fontStyle={"italic"}
              variant={"body2"}
              style={appraisal_text_style}
            >
              ${props.build?.latest_appraisal_price}
            </Typography>
          )}
        </Link>
      </div>
      <Modal
        open={delete_modal_open}
        onClose={handle_delete_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Warn_Banner
            message={
              "You would permanently lose any associated build information or price history."
            }
          />
          <div style={row_container_style}>
            <Button
              type={"submit"}
              variant={"contained"}
              onClick={handle_delete_modal_close}
              sx={{ my: 2, display: "block", marginRight: "10px" }}
            >
              {"Cancel"}
            </Button>
            <Button
              type={"submit"}
              variant={"outlined"}
              onClick={execute_deletion}
              sx={{ my: 2, display: "block" }}
            >
              Delete
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={rename_modal_open}
        onClose={handle_rename_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <TextField label={'New Name'} onChange={(e) => { const sanitized_input = sanitize_input(e.target.value); set_new_name(sanitized_input ? sanitized_input : undefined) }} />

          <div style={row_container_style}>
            <Button
              type={"submit"}
              variant={"contained"}
              onClick={handle_rename_modal_close}
              sx={{ my: 2, display: "block", marginRight: "10px" }}
            >
              {"Cancel"}
            </Button>
            <Button
              type={"submit"}
              variant={"outlined"}
              onClick={execute_rename}
              disabled={new_name === undefined}
              sx={{ my: 2, display: "block" }}
            >
              Rename
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={sold_modal_open}
        onClose={handle_sold_modal_close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Warn_Banner
            message={
              "This will make this build uneditable and mark it as sold. You will still be able to view it in your Garage."
            }
          />
          <TextField label={'How much did you sell for?'} onChange={(e) => { const sanitized_input = sanitize_number_input(e.target.value); set_sold_amount(sanitized_input ? Number(sanitized_input) : undefined) }} />
          <div style={row_container_style}>
            <Button
              type={"submit"}
              variant={"contained"}
              onClick={handle_sold_modal_close}
              sx={{ my: 2, display: "block", marginRight: "10px" }}
            >
              {"Cancel"}
            </Button>
            <Button
              type={"submit"}
              variant={"outlined"}
              onClick={execute_mark_as_sold}
              disabled={sold_amount === undefined}
              sx={{ my: 2, display: "block" }}
            >
              Mark as Sold
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Garage_Item;
