import { compact } from "lodash";
import { Custom_Build } from "../backend-generated-types";

export const get_number_of_components = (cb: Custom_Build): number => {
    return (
        compact([cb.case, cb.motherboard]).length
        + (cb.case_fans?.length ?? 0)
        + (cb.cpus?.length ?? 0)
        + (cb.cpu_coolers?.length ?? 0)
        + (cb.gpus?.length ?? 0)
        + (cb.operating_systems?.length ?? 0)
        + (cb.psus?.length ?? 0)
        + (cb.rams?.length ?? 0)
        + (cb.storages?.length ?? 0)
    );
}

export function sanitize_input(input: string): string {
    return input.replace(/[^a-zA-Z0-9]/g, "");
}

export function sanitize_number_input(input: string): string {
    return input.replace(/[^0-9\.]/g, "");
}